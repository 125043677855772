.senior_wrapper_container {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.908);
  z-index: 10;
  animation: confirmationAnimation 0.4s linear 1;
}

.senior_wrapper_box {
  width: 360px;
  height: 260px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  font-family: Inter;
  /* animation: confirmationBoxAnimation 0.5s linear 1; */
}
.end_call_btn {
  border: none;
  outline: none;
  padding: 7px 35px;
  background-color: #d63131;
  color: white;
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 30px;
  cursor: pointer;
}

.mute_button {
  box-shadow: 0px 0px 2px 0px #858585;
}

.mute_button:hover {
  box-shadow: 0px 0px 1px 0px #858585;
}

.unmute_button {
  box-shadow: inset 0px 0px 2px 0px #858585;
}

.unmute_button:hover {
  box-shadow: inset 0px 0px 1px 0px #858585;
}

.line_on_animation {
  animation: lineOnAnimation 0.4s linear infinite;
}
@keyframes lineOnAnimation {
  0% {
    padding: 3px;
    outline-offset: 3px;
    box-shadow: 0px 0px 10px #00bf19;
  }
  50% {
    /* padding: 5px; */
    outline-offset: 5px;
    box-shadow: 0px 0px 20px #00bf19;
  }
  100% {
    padding: 3px;
    outline-offset: 3px;
    box-shadow: 0px 0px 10px #00bf19;
  }
}
@keyframes confirmationAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes confirmationBoxAnimation {
  from {
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}
