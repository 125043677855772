.imagePreview_container{
 
 height:200px;
 background-color:#eee;
 width:300px;
 position:relative;
 cursor:pointer;
 border-radius:10px
}
.hoverMsg{
    display: none;
    position: absolute;
    height: 200px;
    width: 300px;
    background-color: #0000006d;
    z-index: 10;
    color:white;
}
.imagePreview_container:hover .hoverMsg{
    display: block;
}
.msg_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imagePreview{
    width: 100%;
    height: 100%;
    position: absolute;
   object-fit: cover;
   border-radius: 10px;
}
.img_modal{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:100vh;
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.img_modal_img{
  height: 80%;
}
.cross_logo{
    position: fixed;
    top:0;
    right:0;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: space-between;
}