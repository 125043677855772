.group_heading {
  font-family: Inter;
  font-size: 23px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #121212;
  /* margin-left: 20px; */
  margin-bottom: 0px;
}
.call_spinner g {
  stroke: #fff;
}
.color_call_logo g path {
  stroke: #f1a200;
}
.hoverEffect {
  background-color: #f7f7f7;
}
.hoverEffect:hover {
  background-color: rgba(241, 161, 0, 0.103);
}
.groupSetting_logo:hover circle {
  opacity: 1;
}
.groupSetting_logo:hover g {
  fill: white;
}
.ant-tooltip-inner {
  border-radius: 10px !important;
}
.group_setting {
  position: relative;
}
.group_hide_scrollbar {
  padding: 20px;
}
.group_hide_scrollbar::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.group_hide_scrollbar {
  overflow: -moz-scrollbars-none;
}
.group_load_btn {
  cursor: pointer;
  border: none;
  outline: none;
  color: #f1a200;
  background-color: transparent;
  padding: 2px 7px;
  border-radius: 5px;
}

.group_load_btn:hover {
  background-color: #f1a200;
  color: white;
}

.group_setting:hover .group_setting_dropdown {
  display: block;
}
.group_setting_dropdown {
  display: none;
  position: absolute;
  z-index: 4;
  width: max-content;

  left: -100%;
  border-radius: 12px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.05);
  border: solid 2px rgba(0, 0, 0, 0.04);
  background-color: #fff;
}
.group_setting_dropdown p {
  padding: 10px 15px;
  margin: 5px;
  border-radius: 8px;
  font-weight: bolder;
  cursor: pointer;
}
.group_setting_dropdown:hover p {
  background-color: rgba(241, 161, 0, 0.17);
}
.statusLogo {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.chatLogo {
  height: 36px;
}
.soundLogo {
  height: 36px;
}
.chatLogoText,
.soundLogoText {
  display: "inline-block";
  margin-top: 10px;
  font-weight: bold;
  font-size: 12px;
}
.soundLogoText {
  color: white;
  margin-top: 0px;
}
.group_user_img {
  height: 50px !important;
  width: 50px !important;
  line-height: 2 !important;
}
.group_user_avatar {
  height: 50px !important;
  width: 50px !important;
  line-height: 1.8 !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
.group_user_container {
  height: 85px;
}
.user_name {
  width: 70px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
}
.user_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  position: relative;
}
.sound_container {
  max-width: 100px;
  /* margin-left: 20px; */
  border-radius: 16px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
}

.tempcall {
  background: #f7f7f7;
  border-radius: 10px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  padding: 10px;
}
.call_raise_container {
  width: 100%;
  padding: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo_divider {
  align-self: stretch;
  border: 2px solid white;
  transform: rotate(-40deg);
}
.junior_offline_wrapper {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 60px;
  background-color: rgba(92, 92, 92, 0.7);;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.junior_offline_container {
  background-color: white;
  margin: 0px 20px;
  box-shadow: 2px 5px 15px rgb(54, 54, 54);
  border-radius: 10px;
  padding: 20px;
  
}
@media only screen and (max-width: 768px) {
  .call_raise_container {
    flex-direction: row;
    justify-content: center;
  }
  .logo_divider {
    transform: rotate(0deg);
  }
  .group_hide_scrollbar {
    padding: 5px 20px;
  }
  .statusLogo {
    display: none;
  }
  .sound_container {
    flex-direction: row;
    padding: 2px;
  }
  .chatLogo {
    height: 36px;
  }
  .soundLogo {
    height: 36px;
  }
  .chatLogoText {
    display: none;
  }
  .soundLogoText {
    margin-top: 0px;
    margin-left: 10px;
    font-weight: bolder;
  }
  .group_heading {
    max-width: 400px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .group_setting_dropdown {
    left: -120%;
  }
}

@media only screen and (max-width: 520px) {
  .sound_container {
    max-width: 90%;
  }

  .group_heading {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 24px;
  }
  .group_user_avatar {
    height: 50px !important;
    width: 50px !important;
    line-height: 1.6 !important;
  }
  .group_user_container {
    height: 70px;
  }
  .group_setting_dropdown {
    left: -450%;
  }
  .user_name {
    width: 60px;
  }
  .user_wrapper {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 420px) {
  .group_heading {
    max-width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
