.confirmation_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.808);
  z-index: 10;
  animation: confirmationAnimation 0.2s linear 1;
}
.confirmation_box {
  width: 450px;
  height: 180px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.05);
  font-family: Inter;
}

.confirmation_btn {
  border: none;
  outline: none;
  padding: 5px 40px;
  border-radius: 10px;
  color: white;
  margin-right: 10px;
  cursor: pointer;
  background-color: #f1a200;
}
.green_btn {
  background-color: #00bf19;
}
.red_btn {
  background-color: red;
}
.no_color {
  background-color: transparent;
  color: black;
}

@keyframes confirmationAnimation {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
