.showRecord_btn {
  border: none;
  outline: none;
  background-color: #f1a200;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  height: 40px;
  position: absolute;
  bottom: 0px;
  align-self: flex-start;
  cursor: pointer;
  color: white;
  padding: 8px 50px 8px 20px;
  font-family: Inter;
  border-radius: 8px;
  margin-left: 50px;
  font-size: 16px;
  font-weight: bold;
}

.showRecord_btn:hover {
  background-color: #f1a200a0;
}
.cancel_btn_01 {
  position: absolute;
  right: 30px;

  bottom: 10px;
  cursor: pointer;
}
.responsive_chat_input {
  width: 80%;
  position: absolute;
  bottom: 0px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: #f7f7f7;
  padding: 8px 50px 8px 20px;
  font-family: Inter;
  font-size: 13px;
  outline: none;
  color: #121212;
  align-self: flex-start;
  margin-left: 50px;
}

@media only screen and (max-width: 1100px) {
  .showRecord_btn {
    width: 65%;
  }
}
@media only screen and (max-width: 550px) {
  .showRecord_btn {
    margin-left: 15px;
  }
}
@media only screen and (max-width: 1200px) {
  .responsive_chat_input {
    width: 75%;
  }
}
@media only screen and (max-width: 1000px) {
  .responsive_chat_input {
    width: 70%;
  }
}
