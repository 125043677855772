.juniors_stats {
  position: relative;
}
.junior_stats_wrapper {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 60px;
  background-color: rgba(92, 92, 92, 0.7);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.junior_stats_container {
  max-height: 80vh;
  overflow: auto;
  background-color: white;
  box-shadow: 2px 5px 15px rgb(54, 54, 54);
  border-radius: 10px;
  padding: 20px 40px;
}
.junor_stats_call_logo g path {
  stroke: black;
}
.stats_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
}
.junior_name {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.juniors_show_list_btn {
  background-color: #f1a200;
  color: white;
  outline: none;
  padding: 5px 15px;
  border: 1px solid #f1a200;
  border-radius: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.juniors_stats_btn {
  outline: none;
  padding: 5px 15px;
  color: #f1a200;
  border: 1px solid #f1a200;
  background-color: transparent;
  border-radius: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.downloadCsvBtn{
  color:#f1a200;
  border: 1px dashed #f1a200;
  padding: 3px 10px;
  border-radius: 5px;
}
.downloadCsvBtn:hover{
  color:white;
  background-color: #f1a200;
  
  padding: 3px 10px;
  border-radius: 5px;
}
.date_picker_container{
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: space-between;
}

.date_picker{
   text-align: center;
   color: #aaa;
   border: none;
   border-bottom: 1px dashed #aaa;
   outline: none; 
   
}

.date_picker:hover{
  color: #000;
  border-bottom: 1px dashed #000;
}

@media only screen and (max-width: 700px) {
  .junior_name {
    width: 150px;
  }
  .stats_box {
    width: 75px;
  }
}
@media only screen and (max-width: 500px) {
  .junior_name {
    width: 120px;
  }
  .stats_box {
    width: 50px;
  }
}

@media only screen and (max-width: 450px) {
  .juniors_stats {
    margin-right: 5px;
  }
  .juniors_stats_btn {
    font-size: 10px;
    padding: 5px;
    border-radius: 10px;
  }
  .juniors_show_list_btn {
    font-size: 10px;
    padding: 5px;
    border-radius: 10px;
  }
}
