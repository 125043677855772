.modal_lineOff_logo {
  border: none;
  outline: none;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background-color: #d63131;
  color: hsl(0, 0%, 100%);
  padding: 10px 20px;
  border-radius: 12px;
  font-size: 1em;
}
.chat_input {
  width: 80%;
  position: absolute;
  bottom: 0px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: #f7f7f7;
  padding: 8px 50px 8px 20px;
  font-family: Inter;
  font-size: 13px;
  outline: none;
  color: #121212;
  align-self: flex-start;
  margin-left: 50px;
}
.upload_lineon_btn {
  font-size: 20px;
  color: rgba(18, 18, 18, 0.557) !important;
}
.upload_lineon_btn:hover {
  color: #f1a200 !important;
}
.lineOn_chat {
  margin-left: 15px;
}
.chat_input:focus {
  border: 1px solid #f1a200;
}
.chat_send_logo {
  position: absolute;
  bottom: 8px;
  right: 15%;
  cursor: pointer;
}
.lineOn_chat_logo {
  right: 18%;
}
.chat_logo {
  opacity: 0.2;
}
.lineOn_user_img_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-right: 20px;
}

.lineOn_box_status {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.lineOn_status_group {
  display: flex;
  margin-left: 40px;
  align-self: flex-start;
}
.lineOn_fileIcon {
  left: 15px !important;
}
/* @media only screen and (max-width: 768px) {
  .modal_container {
    width: 80%;
    height: 80%;
  }
} */

@media only screen and (max-width: 420px) {
  .chat_logo {
    width: 130px;
  }
}
@media only screen and (max-width: 550px) {
  .modal_line_on_box {
    flex-direction: column;
  }
  .modal_lineOn_chat {
    display: none;
  }
  .lineOn_heading {
    text-align: center;
    margin-left: 0px !important;
    margin-top: 70px !important;
  }
  .lineOn_user_img_box {
    margin-right: 0px;
  }
  .lineOn_status_group {
    align-self: center;
    margin-left: 0px;
  }
  .user_group_box {
    justify-content: space-evenly;
  }
}
