.disconnected_container {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.908);
  z-index: 10;
}
.disconnected_spinner {
  position: absolute;
  top: 43%;
  left: 43%;
  height: 80px;
  width: 80px;
}
