:root {
  --color-plug: #797979;
}

.main-header {
  display: flex;
  align-items: center;
  padding: 10px 15%;
}

.no_group_msg {
  padding: 100px;
  font-size: 20px;
  font-weight: bolder;
}

.main-header img {
  height: 40px;
}
.plug_on_spin {
  position: fixed;
  top: 60px;
  background-color: rgba(0, 0, 0, 0.7);
  height: 92vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plug_on_spin .spinner {
  height: 50px;
  width: 50px;
}
.plug_on_spin .spinner g {
  stroke: #fff;
}
.start_container {
  position: fixed;
  z-index: 10;
  background-color: rgba(92, 92, 92, 0.7);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.start_day {
  min-width: 350px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 5px 15px rgb(54, 54, 54);
}

.start_btn,
.no_group_invite_btn {
  border: none;
  outline: none;
  background-color: #f1a200;
  color: white;
  padding: 10px 25px;
  border-radius: 10px;
  cursor: pointer;
}

.plugon_msg {
  position: absolute;
  bottom: -60px;
  left: 180px;
  background-image: linear-gradient(to right, #f1a200 0%, #ffc200);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  width: 450px;
  z-index: 5;
}

.plugon_msg::before {
  content: "";
  width: 20px;
  height: 12px;
  background-image: linear-gradient(to right, #f1a200 0%, #ffc200);
  position: absolute;
  top: -10px;
  left: 50%;
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

.header-serach {
  border-radius: 22.4px;
  border: solid 2px rgba(0, 0, 0, 0.06);
  background-color: #f7f7f7;
  padding: 13px;
  margin-right: 16px;
  cursor: pointer;
}

.header-serach img {
  /* width: 36px; */
  height: 30px;
}

.header-create-grp img {
  height: 25px;
  margin-right: 10px;
}

.header-create-grp {
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  border: 2px solid rgba(241, 162, 0, 0.7);
  cursor: pointer;
  padding: 12px;
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #f7f7f7, #f7f7f7);
}

.main_body {
  height: 70vh;
  overflow-y: hidden;
}

.main-header .ant-switch {
  height: 35px;
  width: 120px;
  border-radius: 20px;
  border: none;
  margin-left: 25px;
  background-image: none;
  border: solid 1px rgba(18, 18, 18, 0.15);
  background-color: rgba(18, 18, 18, 0.05);
}

.main-header .ant-switch:focus {
  box-shadow: none;
}

.main-header .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 31px);
}

.main-header .ant-switch-handle {
  top: 6px;
  left: 6px;
}

.main-header .ant-switch-handle::before {
  background-color: var(--color-plug);
  height: 20px;
  width: 20px;
}

.user_img {
  height: 40px;
  width: 40px;
  border-radius: 10px;
  background-color: #f7f7f7;
  margin-left: 20px;
  /* overflow: hidden; */
  cursor: pointer;
  position: relative;
}

.user_drop_menu {
  display: none;
  position: absolute;
  bottom: -101px;
  left: -20px;
}

.user_img_mobile {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  display: none;
  /* flex:1; */
}

.user_drop_menu_mobile {
  display: none;
  position: absolute;
  top: -101px;
  left: -20px;
}

.user_img_mobile:hover .user_drop_menu_mobile {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.05);
  border: solid 2px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  z-index: 10;
  color: black;
}

.user_img:hover .user_drop_menu {
  display: flex;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.05);
  border: solid 2px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  z-index: 10;
}

.logout_ {
  font-size: 13px;
  font-weight: bolder;
  padding: 7px 10px;
  border-radius: 5px;
}

.logout_:hover {
  background-color: rgba(241, 161, 0, 0.196);
}

.groups_container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-left: 14%;
  align-self: flex-start;
  padding-right: 11%;
  width: 85%;
  margin-top: 50px;
}

.add_logo_container {
  position: relative;
  display: flex;
  align-items: center;
}

.add_logo {
  height: 40px;
  cursor: pointer;
}

.add_logo_container:hover .add_logo_dropdown {
  display: block;
  z-index: 5;
}

.add_logo_dropdown {
  display: none;
  position: absolute;
  width: max-content;
  bottom: -50px;
  left: -30px;
  background-color: white;
  padding: 5px;
  border-radius: 12px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.05);
  border: solid 2px rgba(0, 0, 0, 0.04);
}

.add_logo_dropdown p {
  padding: 8px 50px 8px 10px;
  margin: 0px;
  font-size: 12px;
}

.add_logo_dropdown p:hover {
  background-color: rgba(241, 161, 0, 0.299);
  border-radius: 10px;
  cursor: pointer;
}

.plug_on_loader {
  position: absolute;
  left: 8px;
  top: 8px;
}

.plug_on_loader g {
  stroke: #fff;
}

.spinner {
  animation: spin_loader 1.5s linear infinite;
}

.plugApp_logo {
  cursor: pointer;
}

.refresh_container {
  /* position: relative; */
  margin-top: 20px;
  /* bottom: 5px;
  right: 10px; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  /* z-index: 5; */
  /* background-color: #f1a200; */

  /* color: white; */

  font-size: 12px;
  display: flex;
  align-items: center;
}

@keyframes spin_loader {
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .plugon_msg {
    bottom: -80px;
    left: 35%;
    width: 350px;
  }
  .plugon_msg::before {
    left: 90%;
  }
  .groups_container {
    margin-left: 5%;
    width: 94%;
  }

  .header-serach {
    display: none;
  }

  .main-header {
    padding: 10px;
  }

  .header-create-grp {
    display: none;
  }

  .user_img {
    display: none;
  }

  .switch {
    order: 3;
    margin-left: 15px !important;
  }

  .plugApp_logo {
    /* height: 45px !important; */
    cursor: pointer;
  }

  .add_logo_container .add_logo {
    width: 40px;
    height: 40px;
  }

  .plugApp_group {
    margin-right: 0px !important;
  }

  .no_group_msg {
    padding: 50px 20px;
  }
}
@media only screen and (max-width: 540px) {
  .plugon_msg {
    left: 15%;
  }
  .user_img_mobile {
    display: block;
  }
}

@media only screen and (max-width: 430px) {
  .plugApp_logo {
    height: 35px !important;
    cursor: pointer;
  }
  .plugon_msg {
    left: 10px;
  }
  .add_logo_dropdown {
    left: -55px;
  }
}
